import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Card, Col, Divider, Image, Row, Select, Space, Spin, Table, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ColumnsType } from 'antd/es/table';
import depositIcon from 'assets/image/deposit.png';
import depositAmountIcon from 'assets/image/deposit_amount.svg';
import gameProfitIcon from 'assets/image/game_profit.svg';
import lossIcon from 'assets/image/loss.svg';
import profitIcon from 'assets/image/profit.svg';
import promotionIcon from 'assets/image/promotion.png';
import totalDepositIcon from 'assets/image/total_deposit.png';
import totalFeeIcon from 'assets/image/total_fee.png';
import totalWithdrawIcon from 'assets/image/total_withdraw.png';
import userQuantityIcon from 'assets/image/user_quantity.svg';
import withdrawIcon from 'assets/image/withdraw.png';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Tooltip as ChartTooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
} from 'chart.js';
import { DateSelector, LayoutNav } from 'components/layout.component';
import dayjs from 'dayjs';
import { DATE_TYPE } from 'enum/date';
import useAccount from 'hooks/account.hook';
import useSite from 'hooks/site.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { $get } from 'services';
import { setSearchHistory } from 'slice/searchSlice';
import * as common from 'utils/common';
import { toFormatNumber as $f } from 'utils/common';

// 首頁: 數據總覽

// 圖表函式庫
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartTooltip,
  Legend,
  PointElement,
  LineElement,
);

const PageMain: React.FC = () => {
  const site = useSite();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { permissions: $p } = useAccount();
  const { dateType } = useParams();
  const _dateType = dateType ? parseInt(dateType) : null;
  
  const [isLoading, setIsLoading] = useState(true);
  const [date, setDate] = useState([
    dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    _dateType || DATE_TYPE.thisMonth
  ]);
  const handleDateChange = (dates: string[]) => {
    setIsLoading(true)
    setDate(dates);
  }

  // 取得線上人數
  const { data: OnlineMembers, isValidating } = $get({ url: 'api/report/online/members' });

  // 總代列表
  const [SAgentId, setSAgentId] = useState<string>('');
  const { data: saList } = $get({ url: 'api/agent/sa/all/list' });

  const handleSAgentIdChange = (newVal: string) => {
    if (!newVal) {
      setIsLoading(true)
      setTimeout(() => {
        setIsLoading(false)
      }, 800);
    }
    setIsLoading(true)
    setSAgentId(newVal)
  }

  // 每個 card 的 api
  const [newUsers, setNewUsers] = useState<{ [propName: string]: number }>({});
  const { data: _newUsers } = $get({
    url: 'api/report/activity/member',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {

    // NOTE: 要寫全資料載入偵測
    // NOTE: 有值、無值、載入時要維持區域等大不跳動
    setTimeout(() => {
      setIsLoading(false)
    }, 800);

    if (_newUsers) setNewUsers(_newUsers?.Data)
  }, [_newUsers]);

  const [firstDeposit, setFirstDeposit] = useState<{ [propName: string]: number }>({});
  const { data: dataFirstDeposit } = $get({
    url: 'api/report/first/deposit',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (dataFirstDeposit) setFirstDeposit(dataFirstDeposit?.Data)
  }, [dataFirstDeposit]);

  const [totalDeposit, setTotalDeposit] = useState<{ [propName: string]: number }>({});
  const { data: _totalDeposit } = $get({
    url: 'api/report/total/deposit/amount',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_totalDeposit) setTotalDeposit(_totalDeposit?.Data)
  }, [_totalDeposit]);

  const [bonusAmount, setBonusAmount] = useState<{ [propName: string]: number }>({});
  const { data: _bonusAmount } = $get({
    url: 'api/report/bonus/amount',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_bonusAmount) setBonusAmount(_bonusAmount?.Data)
  }, [_bonusAmount]);

  const [depositInfo, setDepositInfo] = useState<{ [propName: string]: number }>({});
  const { data: _depositInfo } = $get({
    url: 'api/report/deposit/info',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_depositInfo) setDepositInfo(_depositInfo?.Data)
  }, [_depositInfo]);

  const [withdrawAmount, setWithdrawAmount] = useState<{ [propName: string]: number }>({});
  const { data: _withdrawAmount } = $get({
    url: 'api/report/total/withdraw/amount',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_withdrawAmount) setWithdrawAmount(_withdrawAmount?.Data)
  }, [_withdrawAmount]);

  // 調帳扣除
  const [withdrawInfo, setWithdrawInfo] = useState<{ [propName: string]: number }>({});
  const { data: _withdrawInfo } = $get({
    url: 'api/report/withdraw/info',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_withdrawInfo) setWithdrawInfo(_withdrawInfo?.Data)
  }, [_withdrawInfo]);

  const [profitInfo, setProfitInfo] = useState<{ [propName: string]: number }>({});
  const { data: _profitInfo } = $get({
    url: 'api/report/profit/info',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_profitInfo) setProfitInfo(_profitInfo?.Data)
  }, [_profitInfo]);

  // 排行區

  const [profitGame, setProfitGame] = useState<RankProvider[]>([]);
  const { data: _profitGame } = $get({
    url: 'api/report/profit/game',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_profitGame) {
      const data = _profitGame?.Data.constructor === Array ? _profitGame?.Data : []
      const ary = data.map((item: any, i: number) => ({
        key: i,
        rank: i + 1,
        provider: item.ProviderName,
        amount: $f(item.TotalWinLossAmount),
        ProviderName: item.ProviderName,
        ProviderCode: item.ProviderCode,
        CategoryCode: item.CategoryCode
      }))
      setProfitGame(ary)
    }
  }, [_profitGame]);

  const [deficitGame, setDeficitGame] = useState<RankProvider[]>([]);
  const { data: _deficitGame } = $get({
    url: 'api/report/deficit/game',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_deficitGame) {
      const data = _deficitGame?.Data.constructor === Array ? _deficitGame?.Data : []
      const ary = data.map((item: any, i: number) => ({
        key: i,
        rank: i + 1,
        provider: item.ProviderName,
        amount: $f(item.TotalWinLossAmount * -1),
        ProviderName: item.ProviderName,
        ProviderCode: item.ProviderCode,
        CategoryCode: item.CategoryCode
      }))
      setDeficitGame(ary)
    }
  }, [_deficitGame]);

  const [profitMember, setProfitMember] = useState<RankAccount[]>([]);
  const { data: _profitMember } = $get({
    url: 'api/report/profit/member',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_profitMember) {
      const data = _profitMember?.Data.constructor === Array ? _profitMember?.Data : []
      const ary = data.map((item: any, i: number) => ({
        key: i,
        rank: i + 1,
        account: item.MemberAccount,
        UpMemberAccount: item.UpMemberAccount,
        sa: item.SAAccount,
        ag: item.AGAccount,
        amount: $f(item.TotalWinLossAmount),
      }))
      setProfitMember(ary)
    }
  }, [_profitMember]);

  // 虧損會員
  const [deficitMember, setDeficitMember] = useState<RankAccount[]>([]);
  const { data: _deficitMember } = $get({
    url: 'api/report/deficit/member',
    params: { DateType: date[2], SAgentId }
  });
  useEffect(() => {
    if (_deficitMember) {
      const data = _deficitMember?.Data.constructor === Array ? _deficitMember?.Data : []
      const ary = data.map((item: any, i: number) => ({
        key: i,
        rank: i + 1,
        account: item.MemberAccount,
        UpMemberAccount: item.UpMemberAccount,
        sa: item.SAAccount,
        ag: item.AGAccount,
        amount: $f(item.TotalWinLossAmount * -1),
      }))
      setDeficitMember(ary)
    }
  }, [_deficitMember]);

  // 列表標題
  const rankProfitProvider: ColumnsType<any> = [
    {
      title: i18n.t('gameProviderCategory'),
      render: (_, record) => `${common.specialProviderName(record.ProviderName, site.data.SiteName, record.ProviderCode)}${i18n.t(record.CategoryCode)}`
    },
    {
      title: i18n.t('profitAmount'),
      dataIndex: "amount",
      align: 'right',
      render: (val) => <span className={common.numColor01(val)}>{val}</span>
    },
  ]
  const rankLossProvider: ColumnsType<any> = [
    {
      title: i18n.t('gameProviderCategory'),
      render: (_, record) => `${common.specialProviderName(record.ProviderName, site.data.SiteName, record.ProviderCode)}${i18n.t(record.CategoryCode)}`
    },
    {
      title: i18n.t('lossAmount'),
      dataIndex: "amount",
      align: 'right',
      render: (val) => <span className={common.numColor01(val)}>{val}</span>
    },
  ]
  const rankProfitAccount: ColumnsType<RankAccount> = [
    {
      title: `${i18n.t('account')}`,
      dataIndex: "account",
      width: '30%',
      // sorter: (a, b) => a.rank - b.rank,
      defaultSortOrder: "ascend",
      render: (_, { account }) => <Link to={`/member/account/${account}`}>{account}</Link>
    },
    {
      title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
      width: '40%',
      render: (_, record) => (
        <span>{record.UpMemberAccount || '-'}／{record.ag}／{record.sa}</span>
      )
    },
    {
      title: i18n.t('profitAmount'),
      dataIndex: "amount",
      width: '30%',
      align: 'right',
      render: (_, record) => (
        <span className={common.numColor01(_)}>{_}</span>
      )
    },
  ]
  const rankLossAccount: ColumnsType<RankAccount> = [
    {
      title: `${i18n.t('account')}`,
      // title: `${i18n.t('ranking')}／${i18n.t('account')}`,
      dataIndex: "account",
      width: '30%',
      // sorter: (a, b) => a.rank - b.rank,
      defaultSortOrder: "ascend",
      render: (_, { account }) => <Link to={`/member/account/${account}`}>{account}</Link>
    },
    {
      title: `${i18n.t('recommendedMembers')}／${i18n.t('agent')}／${i18n.t('generalAgent')}`,
      width: '40%',
      render: (_, record) => (
        <span>{record.UpMemberAccount || '-'}／{record.ag}／{record.sa}</span>
      )
    },
    {
      title: i18n.t('lossAmount'),
      dataIndex: "amount",
      width: '30%',
      align: 'right',
      render: (_, record) => (
        <span className={common.numColor01(_)}>{_}</span>
      )
    },
  ]

  // 圖表設定
  const [chartOptions, setChartOptions] = useState<any>({
    responsive: true,
    scales: {
      y: {
        ticks: {
          stepSize: 1
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom' as const,
      },
    },
  });
  // 取得圖表資訊
  const [reportActivityList, setReportActivityList] = useState<TableAttributes>({});
  const { data: _reportActivityList } = $get({
    url: 'api/report/activity/list',
    params: {
      SAgentId,
      StartDate: common.timeL2S(date[0] as any),
      EndDate: common.timeL2S(date[1] as any),
    }
  });
  useEffect(() => {
    if (_reportActivityList && _reportActivityList.Data) {
      const data = _reportActivityList.Data.map((item: any, i: number) => {
        const _date = item.days.split('T')
        const _days = _date[0].split('-')

        return {
          labels: `${_days[1]}-${_days[2]}`,
          data1: item.NewMemberCount,
          data2: item.ActiveMemberCount,
          data3: item.TotalMemberCount,
          data4: item.TotalWinLossAmount,
        };
      })
      setReportActivityList({
        dataSource: data,
        total: _reportActivityList.TotalRecord
      })
    } else {
      setReportActivityList({
        dataSource: [],
        total: 0
      })
    }
  }, [_reportActivityList]);
  // 左圖表
  const [barData, setBarData] = useState<any>({});
  useEffect(() => {
    setBarData({
      labels: reportActivityList.dataSource?.map(a => a.labels),
      datasets: [
        {
          label: i18n.t('newUsers'),
          data: reportActivityList.dataSource?.map(a => a.data1),
          backgroundColor: '#AFC8EA',
        },
        {
          label: i18n.t('activeMembers'),
          data: reportActivityList.dataSource?.map(a => a.data2),
          backgroundColor: '#CBEAD9',
        },
        {
          label: i18n.t('bettingMembers'),
          data: reportActivityList.dataSource?.map(a => a.data3),
          backgroundColor: '#E79F9C',
        },
      ],
    });
  }, [reportActivityList]);
  // 遊戲盈利
  const [lineData, setLineData] = useState<any>({});
  useEffect(() => {
    setLineData({
      labels: reportActivityList.dataSource?.map(a => a.labels),
      datasets: [
        {
          label: i18n.t('gameProfit'),
          data: reportActivityList.dataSource?.map(a => a.data4),
          borderColor: '#4874AD',
          backgroundColor: '#4874AD',
        },
      ],
    });
  }, [reportActivityList.dataSource]);

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Row align="middle" gutter={[16, 16]}>
          {/* 帳號與日期選擇 */}
          <Col span={24}>
            <Row align="middle" justify="space-between">
              {/* 靠左 */}
              <Col>
                <Row gutter={[12, 12]} align="middle">
                  <Col>
                    <Select
                      showSearch
                      filterOption={common.selectFuzzyFilter}
                      className="w-12"
                      placeholder={`${i18n.t('generalAgent')}`}
                      options={[
                        { value: '', label: `${i18n.t('ALL')}` },
                        ...((saList && saList.Data) || []).map((item: any) => (
                          { value: item.Id, label: item.Account }
                        ))
                      ]}
                      onChange={handleSAgentIdChange}
                    />
                  </Col>
                  {/* 時間區間 */}
                  <DateSelector date={date} setDate={handleDateChange} displayCom={['Radio']}
                    defaultDateType={_dateType || DATE_TYPE.thisMonth} />
                </Row>
              </Col>
              {/* 靠右 */}
              <Col>
                <Row justify="end" align="middle">
                  {$p('60401') &&
                    <Button style={{ background: '#F7AE4F' }} className="color-01 mr-1"
                      onClick={() => navigate('/report/game')}>
                      {i18n.t('gameStatisticalAnalysis')}
                    </Button>}
                  {$p('60301') &&
                    <Button style={{ background: '#4FCAB4' }} className="color-01 mr-1"
                      onClick={() => navigate('/report/business')}>
                      {i18n.t('operationReports')}
                    </Button>}
                </Row>
              </Col>
            </Row>
          </Col>
          {/* 在線人數 */}
          <Col span={24}>
            <Row align="middle">
              <Col className="size-16">{i18n.t('dataOverview')}</Col>
              <Spin spinning={isValidating}>
                <Col className="online-num center" style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setSearchHistory({ member: null }));
                    navigate(`/member?isOnline=1`);
                  }}>
                  {i18n.t('onlineMembers')} {$f(OnlineMembers?.Data.Member, 0) || 0}
                </Col>
              </Spin>
            </Row>
          </Col>
          {/* 第一排 */}
          <Col span={24}>
            <Row align="middle" gutter={[12, 12]} justify="space-between">
              {/* 新用戶數 */}
              <Col span={10}>
                <Card className="card-shadow" loading={isLoading}>
                  <Row align="middle" justify="space-around">
                    <Col><Image src={userQuantityIcon} preview={false} /></Col>
                    <Col>
                      {/* 新用戶數 */}
                      <Space direction="vertical" align="center">
                        <div className="size-14 color-03">{i18n.t('newUsers')}</div>
                        <div className="size-28 color-04 font-w-md">{$f(newUsers.NewMemberCount, 0)}</div>
                      </Space>
                    </Col>
                    <Divider className="h-3" type="vertical" />
                    <Col>
                      {/* 活躍人數 */}
                      <Space direction="vertical" align="center">
                        <div className="size-14 color-03">
                          <Row align="middle">
                            <Col>{i18n.t('activeMembers')}</Col>
                            <Col>
                              <Tooltip placement="top" title={i18n.t('membersWithBets')}>
                                <InfoCircleFilled style={{ marginLeft: 2 }} />
                              </Tooltip>
                            </Col>
                          </Row>
                        </div>
                        <div className="size-28 color-04 font-w-md">{$f(newUsers.ActiveMemberCount, 0)}</div>
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {/* 首存金額 */}
              <Col span={14}>
                <Card className="card-shadow" loading={isLoading}>
                  <Row align="middle" justify="space-around">
                    <Col><Image src={depositAmountIcon} preview={false} /></Col>
                    <Col>
                      <Space direction="vertical" align="center">
                        <div className="size-14 color-03">{i18n.t('firstDepositAmount')}</div>
                        <div className="size-28 color-04 font-w-md">{$f(firstDeposit.TotalFirstDepositAmount)}</div>
                      </Space>
                    </Col>
                    <Divider className="h-3" type="vertical" />
                    <Col>
                      <Space direction="vertical" align="center">
                        <div className="size-14 color-03">{i18n.t('firstDepositMembers')}</div>
                        <div className="size-28 color-04 font-w-md">{$f(firstDeposit.TotalFirstTimeDepositMemberCount, 0)}</div>
                      </Space>
                    </Col>
                    <Divider className="h-3" type="vertical" />
                    <Col>
                      <Space direction="vertical" align="center">
                        <div className="size-14 color-03">{i18n.t('redepositMembers')}</div>
                        <div className="size-28 color-04 font-w-md">{$f(firstDeposit.TotalSecondTimeDepositMemberCount, 0)}</div>
                      </Space>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* 彩卡區 */}
          <Col span={24}>
            <Row align="middle" justify="space-between" gutter={[16, 16]}>
              {/* 彩卡區 */}
              <Col span={16}>
                <Row align="middle" gutter={[16, 16]}>
                  {/* 上排 */}
                  <Col span={24}>
                    <Row align="middle" gutter={[16, 16]}>
                      {/* 總存款金額 */}
                      <Col span={8}>
                        <Card className="card-shadow card-bg-01" loading={isLoading}
                          bodyStyle={{ minHeight: 150, maxHeight: 150 }}>
                          <Row className="color-01" justify="space-between" align="middle">
                            <Col span={8}><Image src={totalDepositIcon} preview={false} /></Col>
                            <Col span={16} className="text-end">
                              <Row justify="end">
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col className="size-14 font-w-md">{i18n.t('totalDepositAmount')}</Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col span={24} className="size-22">{$f(totalDeposit.TotalDepositAmount)}</Col>
                                  </Row>
                                </Col>
                                <Col span={24} className="mt-1">
                                  <Space direction="horizontal" className="size-12" size={2}>
                                    <div>{$f(totalDeposit.TotalMemberCount, 0)} {i18n.t('people')}｜</div>
                                    <div>{$f(totalDeposit.TotalDepositRecordsCount, 0)} {i18n.t('counts')}</div>
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      {/* 優惠金額 */}
                      <Col span={8}>
                        <Card className="card-shadow card-bg-02" loading={isLoading}
                          bodyStyle={{ minHeight: 150, maxHeight: 150 }}>
                          <Row className="color-01" justify="space-between" align="middle">
                            <Col span={8}><Image src={promotionIcon} preview={false} /></Col>
                            <Col span={16} className="text-end">
                              <Row justify="end">
                                <Col span={24}>
                                  <Row align="middle" gutter={[5, 0]} justify="end">
                                    <Col className="size-14 font-w-md">{i18n.t('promotionAmount')} /</Col>
                                    <Col className="size-14 font-w-md">{i18n.t('adjustmentPromotion')}</Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col span={24} className="size-18">{$f(bonusAmount.TotalBonusAmount)} /</Col>
                                    <Col span={24} className="size-18" style={{ paddingRight: '0.6rem' }}>
                                      {$f(bonusAmount.TotalBonusAdjustAmount)}
                                    </Col>
                                  </Row>
                                </Col>
                                <Col span={24} className="size-12">
                                  <Row align="middle" justify="end">
                                    <Col className="size-12 text-end">
                                      <div>
                                        <span>{$f(bonusAmount.TotalBonusMemberCounts, 0)} {i18n.t('people')}｜</span>
                                        <span>{$f(bonusAmount.TotalBonusRecords, 0)} {i18n.t('counts')}／</span>
                                      </div>
                                    </Col>
                                    <Col className="size-12 text-end">
                                      <div>
                                        <span>{$f(bonusAmount.TotalBonusAdjustMemberCounts, 0)} {i18n.t('people')}｜</span>
                                        <span>{$f(bonusAmount.TotalBonusAdjustRecords, 0)} {i18n.t('counts')}</span>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      {/* 調帳添加*/}
                      <Col span={8}>
                        <Card className="card-shadow card-bg-03" bodyStyle={{ minHeight: 150, maxHeight: 150 }} loading={isLoading}>
                          <Row className="color-01" justify="space-between" align="middle">
                            <Col span={8}><Image src={depositIcon} preview={false} /></Col>
                            <Col span={16} className="text-end">
                              <Row justify="end">
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col className="size-14 font-w-md">{i18n.t('adjustmentAccounting')}</Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col span={24} className="size-22">{$f(depositInfo.TotalAdjustPlusAmount)}</Col>
                                  </Row>
                                </Col>
                                <Col span={24} className="mt-1">
                                  <Space direction="horizontal" className="size-12" size={2}>
                                    <div>{$f(depositInfo.TotalAdjustPlusMemberCount, 0)} {i18n.t('people')}｜</div>
                                    <div>{$f(depositInfo.TotalAdjustPlusRecordsCount, 0)} {i18n.t('counts')}</div>
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  {/* 下排 */}
                  <Col span={24}>
                    <Row align="middle" justify="space-around" gutter={[16, 16]}>
                      {/* 總出款金額 */}
                      <Col span={8}>
                        <Card className="card-shadow card-bg-04" loading={isLoading}
                          bodyStyle={{ minHeight: 150, maxHeight: 150 }}>
                          <Row className="color-01" align="middle">
                            <Col span={8}><Image src={totalWithdrawIcon} preview={false} /></Col>
                            <Col span={16} className="text-end">
                              <Row justify="end">
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col className="size-14 font-w-md">{i18n.t('totalWithdrawalAmount')}</Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col span={24} className="size-22">{$f(withdrawAmount.TotalWithdrawAmount)}</Col>
                                  </Row>
                                </Col>
                                <Col span={24} className="mt-1">
                                  <Space direction="horizontal" className="size-12" size={2}>
                                    <div>{$f(withdrawAmount.TotalMemberCount, 0)} {i18n.t('people')}｜</div>
                                    <div>{$f(withdrawAmount.TotalWithdrawRecordsCount, 0)} {i18n.t('counts')}</div>
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      {/* 總出款手續費 */}
                      <Col span={8}>
                        <Card className="card-shadow card-bg-05" loading={isLoading}
                          bodyStyle={{ minHeight: 150, maxHeight: 150 }}>
                          <Row className="color-01" justify="space-between" align="middle">
                            <Col span={8}><Image src={totalFeeIcon} preview={false} /></Col>
                            <Col span={16} className="text-end">
                              <Row justify="end">
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col className="size-14 font-w-md">{i18n.t('totalWithdrawalFee')}</Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col span={24} className="size-22">{$f(withdrawAmount.TotalWithdrawHandlingFeeAmount)}</Col>
                                  </Row>
                                </Col>
                                <Col span={24} className="mt-1">
                                  <Space direction="horizontal" className="size-12" size={4}>
                                    <div>{$f(withdrawAmount.TotalMemberCount, 0)} {i18n.t('people')}｜</div>
                                    <div>{$f(withdrawAmount.TotalWithdrawRecordsCount, 0)} {i18n.t('counts')}</div>
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                      {/* 調帳扣除 */}
                      <Col span={8}>
                        <Card className="card-shadow card-bg-06" loading={isLoading}
                          bodyStyle={{ minHeight: 150, maxHeight: 150 }}>
                          <Row className="color-01" justify="space-between" align="middle">
                            <Col span={8}><Image src={withdrawIcon} preview={false} /></Col>
                            <Col span={16} className="text-end">
                              <Row justify="end">
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col className="size-14 font-w-md">{i18n.t('adjustmentDeduction')}</Col>
                                  </Row>
                                </Col>
                                <Col span={24}>
                                  <Row align="middle" justify="end">
                                    <Col span={24} className="size-22">{$f(withdrawInfo.TotalAdjustMinusAmount)}</Col>
                                  </Row>
                                </Col>
                                <Col span={24} className="mt-1">
                                  <Space direction="horizontal" className="size-12" size={2}>
                                    <div>{$f(withdrawInfo.TotalMemberCount, 0)} {i18n.t('people')}｜</div>
                                    <div>{$f(withdrawInfo.TotalAdjustMinusRecordsCount, 0)} {i18n.t('counts')}</div>
                                  </Space>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              {/* 遊戲盈利 */}
              <Col span={8}>
                <Card className="card-shadow" loading={isLoading}
                  bodyStyle={{ paddingLeft: 5, paddingRight: 5, paddingTop: 1, paddingBottom: 1 }}>
                  <Row>
                    <Col span={24} className="pr-1 pl-1 pt-1">
                      <Row align="middle" justify="space-between">
                        <Col><Image src={gameProfitIcon} preview={false} /></Col>
                        <Col className="text-end">
                          <Space direction="vertical">
                            <div className="size-14 color-03">{i18n.t('memberWinLoss')}</div>
                            <div className="size-30 font-w-md color-04">{$f(profitInfo.TotalWinLossAmount)}</div>
                          </Space>
                        </Col>
                      </Row>
                    </Col>
                    <Divider style={{ marginTop: 14, marginBottom: 14 }} />
                    <Col span={24} className="pr-1 pl-1 pb-1">
                      <Row>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Col className="size-14 color-03">{i18n.t('bettingMembers')}</Col>
                            <Col className="size-22 font-w-sm color-04">{$f(profitInfo.TotalMemberCount, 0)}</Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Col className="size-14 color-03">{i18n.t('totalBets')}</Col>
                            <Col className="size-22 font-w-sm color-04">{$f(profitInfo.TotalBetCount, 0)}</Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Col className="size-14 color-03">{i18n.t('totalBet')}</Col>
                            <Col className="size-22 font-w-sm color-04">{$f(profitInfo.TotalBetAmount)}</Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Col className="size-14 color-03">{i18n.t('betAmountValid')}</Col>
                            <Col className="size-22 font-w-sm color-04">{$f(profitInfo.TotalValidBetAmount)}</Col>
                          </Row>
                        </Col>
                        <Col span={24}>
                          <Row align="middle" justify="space-between">
                            <Col className="size-14 color-03">{i18n.t('totalPayout')}</Col>
                            <Col className="size-22 font-w-sm color-04">{$f(profitInfo.TotalSettleAmount)}</Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* 數據圖表 */}
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Card className="card-shadow" loading={isLoading}>
                  <div>
                    <Row>
                      <Col span={12}><Bar options={chartOptions} data={barData} /></Col>
                      <Col span={12}><Line options={chartOptions} data={lineData} /></Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
          {/* 排行 */}
          <Col span={24}>
            <Row justify="space-between" gutter={[16, 16]}>
              {/* 盈利遊戲: 資料庫是會員角度 故站長盈虧串api故意對調 */}
              <Col span={6}>
                <Card className="card-shadow" loading={isLoading}
                  title={
                    <Row align="middle" gutter={[12, 12]}>
                      <Col><Image src={profitIcon} preview={false} /></Col>
                      <Col className="size-16">{i18n.t('profitableGames')}</Col>
                    </Row>
                  }
                  headStyle={{ background: '#FF5252', color: '#fff' }}
                  bodyStyle={{ padding: 0 }}>
                  <Table
                    pagination={false}
                    columns={rankProfitProvider}
                    dataSource={deficitGame}
                    scroll={{ y: 270 }}
                  />
                </Card>
              </Col>
              {/* 虧損遊戲: 資料庫是會員角度 故站長盈虧串api故意對調 */}
              <Col span={6}>
                <Card className="card-shadow" loading={isLoading}
                  title={
                    <Row align="middle" gutter={[12, 12]}>
                      <Col><Image src={lossIcon} preview={false} /></Col>
                      <Col className="size-16">{i18n.t('losingGames')}</Col>
                    </Row>
                  }
                  headStyle={{ background: '#00DA8B', color: '#fff' }}
                  bodyStyle={{ padding: 0 }}>
                  <Table
                    pagination={false}
                    columns={rankLossProvider}
                    dataSource={profitGame}
                    scroll={{ y: 270 }}
                  />
                </Card>
              </Col>
              {/* 盈利會員 */}
              <Col span={6}>
                <Card className="card-shadow" loading={isLoading}
                  title={
                    <Row align="middle" gutter={[12, 12]}>
                      <Col><Image src={profitIcon} preview={false} /></Col>
                      <Col className="size-16">{i18n.t('profitableMembersTop20')}</Col>
                    </Row>
                  }
                  headStyle={{ background: '#FF5252', color: '#fff' }}
                  bodyStyle={{ padding: 0 }}>
                  <Table
                    pagination={false}
                    columns={rankProfitAccount}
                    dataSource={profitMember}
                    scroll={{ y: 270 }}
                  />
                </Card>
              </Col>
              {/* 虧損會員 */}
              <Col span={6}>
                <Card className="card-shadow" loading={isLoading}
                  title={
                    <Row align="middle" gutter={[12, 12]}>
                      <Col><Image src={lossIcon} preview={false} /></Col>
                      <Col className="size-16">{i18n.t('losingMembersTop20')}</Col>
                    </Row>
                  }
                  headStyle={{ background: '#00DA8B', color: '#fff' }}
                  bodyStyle={{ padding: 0 }}>
                  <Table
                    pagination={false}
                    columns={rankLossAccount}
                    dataSource={deficitMember}
                    scroll={{ y: 270 }}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </div >
  );
};

export default PageMain;
