import { Button, Col, Form, Row, Select, Spin, message, Card, Space, Radio, Image, Typography} from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { LayoutNav } from 'components/layout.component';
import { RESPONSE_CODE_LAYOUT_SETTING } from "constants/response";
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { $get, $post } from "services";

// 版型設置

const PageMain: React.FC = () => {
  const { Title } = Typography;
  const { permissions: $p } = useAccount();
  const [form] = useForm();
  const [isEditDone, setIsEditDone] = useState(false);

  // 更新版型
  const onFinish = (formData: any) => {
    $post({
      url: 'api/template/settings/update',
      send: {
        desktop: {
          layout: formData.desktopLayout,
          theme: formData.desktopTheme
        },
        mobile: {
          layout: formData.mobileLayout,
          theme: formData.mobileTheme
        }
      },
      success: () => {
        message.success(i18n.t('operationSuccess'));
        setIsEditDone(false);
      },
      resCode: RESPONSE_CODE_LAYOUT_SETTING
    })
  }

  const onCancel = () => {
    setIsEditDone(false);
  }

 // 取得預設版型資訊
  const { data: defaultLayoutInfo } = $get({
    url: 'api/template/settings',
  })

  // 取得所有版型資訊
  const { data: layoutInfo } = $get({
    url: 'api/template/settings/param',
  })

  useEffect(() => {
    if (defaultLayoutInfo && layoutInfo) {
      const { Desktop, Mobile } = defaultLayoutInfo.Data;
      form.setFieldsValue({
        desktopLayout: Desktop?.layout,
        desktopTheme: Desktop?.theme,
        mobileLayout: Mobile?.layout,
        mobileTheme: Mobile?.theme,
      })
      setSelectedDesktopLayout(Desktop?.layout);
      setSelectedMobileLayout(Mobile?.layout);
      setSelectedDesktopTheme(Desktop?.theme);
      setSelectedMobileTheme(Mobile?.theme);
    }
  }, [defaultLayoutInfo, layoutInfo]);


  const [layoutInfoList, setLayoutInfoList] = useState([]);

  useEffect(() => {
    if (layoutInfo && layoutInfo?.Data) {
      const result: any = [];
      const cdnBaseUrl = layoutInfo?.CdnUri;
      for (const device in layoutInfo?.Data) {
        for (const layout in layoutInfo?.Data[device]) {
          const themes = layoutInfo?.Data[device][layout]?.themes;
          if (themes && Array.isArray(themes)) {
            themes.forEach((theme: any) => {
              const cdnUrl = `${cdnBaseUrl}/images/template/${device}/${layout}/${theme}.png`;
              result.push({ device, layout, themes: theme, cdnUrl });
            });
          }
        }
      }
      setLayoutInfoList(result);
      setDesktopLoading(false);
      setMobileloading(false);
    }
  }, [layoutInfo]);


  // Desktop-選取layout產生對應theme
  const [selectedDesktopLayout, setSelectedDesktopLayout] = useState(defaultLayoutInfo?.Data?.Desktop?.layout);
  const [desktopLoading, setDesktopLoading] = useState(true);
  const handleDesktopLayoutChange = (value: any) => {
    setSelectedDesktopLayout(value);
    setDesktopLoading(true);
    setTimeout(() => setDesktopLoading(false), 500);
  };

  // Mobile選取layout產生對應theme
  const [selectedMobileLayout, setSelectedMobileLayout] = useState(defaultLayoutInfo?.Data?.Mobile?.layout);
  const [mobileloading, setMobileloading] = useState(true);
  const handleMobileLayoutChange = (value: any) => {
    setSelectedMobileLayout(value);
    setMobileloading(true);
    setTimeout(() => setMobileloading(false), 500);
  }

  // Desktop-theme Radio預設勾選相關
  const [selectedDesktopTheme, setSelectedDesktopTheme] = useState<string | undefined>(undefined);
  const handleDesktopThemeChange = (e: any) => {
    const newValue = e.target.value;
    setSelectedDesktopTheme(newValue);
    form.setFieldsValue({ desktopTheme: newValue });
  };

  // Mobile-theme Radio預設勾選相關
  const [selectedMobileTheme, setSelectedMobileTheme] = useState<string | undefined>(undefined);
  const handleMobileThemeChange = (e: any) => {
    const newValue = e.target.value;
    setSelectedMobileTheme(newValue);
    form.setFieldsValue({ mobileTheme: newValue });
  };

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2 pt-1">
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[10, 0]}>

            {/* 編輯列 */}
            <Col span={24} style={{ display: "flex", justifyContent: "end" }}>
              {
                isEditDone && <>
                  <Button className="mr-1" onClick={onCancel}>{i18n.t('cancel')}</Button>
                  <Button type="primary" onClick={form.submit}>{i18n.t('confirm')}</Button>
                </>
              }
              {
                !isEditDone && $p('50602') &&
                <Button type="primary" onClick={() => setIsEditDone(!isEditDone)}>{i18n.t('edit')}</Button>
              }
            </Col>

            {/* 左 Desktop */}
            <Col span={12} >
              <Spin spinning={desktopLoading}>
                <Title level={4} style={{ marginTop: 0 }}>Desktop</Title>
                <div className="mt-1"><span className="require">*</span>{i18n.t('layout')}</div>
                <Form.Item name="desktopLayout">
                  <Select
                    disabled={($p('50601') && !$p('50602')) ? false : !isEditDone }
                    className="w-12"
                    onChange={handleDesktopLayoutChange}
                    options={
                      layoutInfo &&
                      [...new Set(  // 使用 Set 去掉重複
                        layoutInfoList
                          .filter((item: any) => item.device === "desktop")
                          .map((item: any) => item.layout)
                      )].map((layout: any) => {
                        return { value: layout, label: layout };
                      })
                    }
                  />
                </Form.Item>
                <Form.Item name="desktopTheme">
                  <Card className="mt-1">
                    <Radio.Group
                      disabled={($p('50601') && !$p('50602')) ? false : !isEditDone }
                      style={{ display: 'flex', flexDirection: 'column' }}
                      value={selectedDesktopTheme}
                      onChange={handleDesktopThemeChange}
                    >
                      <Row gutter={[24, 24]}>
                        { layoutInfoList && layoutInfoList
                          .filter((item: any) => item.device === 'desktop' && item.layout === selectedDesktopLayout)
                          .map((template: any, index) => {
                            return (
                              <Col span={8} key={index} style={{ textAlign: 'center' }}>
                                <Space direction="vertical" align="start" >
                                  <Radio value={template.themes}>
                                    {template.themes}
                                  </Radio>
                                    <Image
                                      width="270px"
                                      src={template.cdnUrl}
                                      preview={false}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => window.open(template.cdnUrl, '_blank')}
                                    />
                                </Space>
                              </Col>
                            )
                          })}
                      </Row>
                    </Radio.Group>
                  </Card>
                </Form.Item>
              </Spin>
            </Col>
            
            {/* 右 Mobile */}
            <Col span={12}>
              <Spin spinning={mobileloading}>
                <Title level={4} style={{ marginTop: 0 }}>Mobile</Title>
                <div className="mt-1"><span className="require">*</span>{i18n.t('layout')}</div>
                <Form.Item name="mobileLayout">
                  <Select
                    disabled={($p('50601') && !$p('50602')) ? false : !isEditDone }
                    className="w-12"
                    onChange={handleMobileLayoutChange}
                    options={
                      layoutInfo &&
                      [...new Set(  // 使用 Set 去掉重複
                        layoutInfoList
                          .filter((item: any) => item.device === "mobile")
                          .map((item: any) => item.layout)
                      )].map((layout: any) => {
                        return { value: layout, label: layout };
                      })
                    }
                  />
                </Form.Item>
                <Form.Item name="mobileTheme" >
                  <Card  className="mt-1">
                    <Radio.Group
                      disabled={($p('50601') && !$p('50602')) ? false : !isEditDone }
                      style={{ display: 'flex', flexDirection: 'column' }}
                      value={selectedMobileTheme}
                      onChange={handleMobileThemeChange}
                    >
                      <Row gutter={[24, 24]}>
                        { layoutInfoList && layoutInfoList
                          .filter((item: any) => item.device === 'mobile' && item.layout === selectedMobileLayout)
                          .map((template: any, index) => {
                            return (
                              <Col span={8} key={index} style={{ textAlign: 'center' }}>
                                <Space direction="vertical" align="start" >
                                  <Radio value={template.themes}>
                                    {template.themes}
                                  </Radio>
                                  <Image
                                    width="270px"
                                    src={template.cdnUrl}
                                    preview={false}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => window.open(template.cdnUrl, '_blank')}
                                  />
                                </Space>
                              </Col>
                            )
                          })}
                      </Row>
                    </Radio.Group>
                  </Card>
                </Form.Item>
              </Spin>
            </Col>

          </Row>
        </Form>
      </Content>
    </div>
  );
};

export default PageMain;