import { Button, Col, Form, Input, Row, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { LayoutNav, LayoutPagination } from 'components/layout.component';
import i18n from 'i18n';
import React, { useState } from 'react';

// 遊戲IP檢核 > IP封鎖管理(停用)

const PageMain: React.FC = () => {
  const [form] = useForm();

  const [page, setPage] = useState<number[]>([1, 10]);
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [params, setParams] = useState<any>({});

  const onEdit = () => {
    setIsOpenEdit(true);
  }

  const onDelete = () => {
    setIsOpenDelete(true);
  }

  const onFinish = (formData: any) => {
    setPage([1, page[1]]);
    setParams({})
  }

  const onClear = () => {
    form.resetFields();
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content>
        <Form form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            {/* 搜尋列 */}
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item name="ip">
                    <Input placeholder='IP' />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Button type="primary" onClick={() => setIsOpenAdd(true)}>{i18n.t('add')}</Button>
            </Col>
            {/* 資料 */}
            <Col span={24}>
              <Table
                size="small"
                dataSource={[]}
                columns={[
                  {
                    title: i18n.t('IP'),
                    dataIndex: 'ip',
                    width: '35%',
                  },
                  {
                    title: i18n.t('status'),
                    dataIndex: 'state',
                    width: '15%',
                  },
                  {
                    title: i18n.t('remark'),
                    dataIndex: 'remark',
                    width: '35%',
                  },
                  {
                    title: i18n.t('operation'),
                    width: '15%',
                    render: (_, record) => (
                      <>
                        <Button className="size-12" type="link" onClick={() => onEdit()}>
                          {i18n.t('edit')}
                        </Button>
                        <Button className="size-12" type="link" onClick={() => onDelete()}>
                          {i18n.t('delete')}
                        </Button>
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={0} setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>
      {/* Modal */}
      {/* <PopupAddIpModal isOpen={isOpenAddModal} close={() => setIsOpenAddModal(false)} />
      <PopupEditIpModal data={singleData} isOpen={isOpenEditModal} close={() => setIsOpenEditModal(false)} />
      <PopupInfoIpModal isOpen={isOpenInfoModal} close={() => setIsOpenInfoModal(false)} />
      <PopupDeleteIp data={singleData} isOpen={isOpenDeleteModal} close={() => setIsOpenDeleteModal(false)} /> */}
    </div >
  );
};

export default PageMain;