// 代理錢包紀錄
// 帳目
export enum AGENT_LOG {
  depositing = 1006,
  withdrawal = 1011,
  // withdrawalSuccessful = 1013, // 財務審核通過
  withdrawalDenied = 1012,     // 財務審核拒絕
  cashbackDeposit = 1020,
  revenueDeposit = 1023,
  adjustmentAddBalance = 1001,
  adjustmentDeductBalance = 1002,
  agentTransfer = 1031,
  transferOut = 1032,
}
// 帳戶紀錄
export enum MEMBER_LOG {
  bet = 2002,
  payout = 2004,
  depositing = 1006,
  withdrawal = 1011,
  withdrawalDenied = 1012,
  adjustmentAddBalance = 1001,
  adjustmentDeductBalance = 1002,
  memberLevelPromotion = 2020,
  systemDiscountsDistributed = 1015,
  dddPromotion = 1018,
  deductPromotion = 1019,
  rebate = 1020,
  transferInput = 2018,
  transferOutput = 2017,
  transferIn = 1031,
  transferOut = 1032,
  raise = 2102,
  donate = 2016,
  unsettledCancellation = 2003,
  settledCancellation = 2008,
  cancelPayout = 2005,
  providerPromotions = 2015
}
