import { ArrowDownOutlined, GlobalOutlined, MoreOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Card, Col, Collapse, Descriptions, Form, Image, Input, Modal, Popover, Row, Select, Space, Spin, Tree, message } from "antd";
import ImgCrop from "antd-img-crop";
import { Tabs, Upload, UploadFile } from "antd/lib";
import { useForm } from "antd/lib/form/Form";
import TextArea from "antd/lib/input/TextArea";
import { DirectoryTreeProps } from "antd/lib/tree";
import userDefaultAvatarIcon from 'assets/image/avatar-default.png';
import bell01Icon from 'assets/image/bell-01.svg';
import bell02Icon from 'assets/image/bell-02.svg';
import errorIcon from 'assets/image/erroricon.png';
import hintIcon from 'assets/image/icon-12.png';
import uploadPhotoMessageIcon from 'assets/image/uploadPhotoMessage.svg';
import { RESPONSE_CODE_CHAT } from "constants/response";
import dayjs from "dayjs";
import { AVATAR_TYPE, CHAT_TYPE, SERVICE_STATUS } from "enum/chat";
import { ROLE } from "enum/socket";
import i18n from "i18n";
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { ChatList, MessageList } from "react-chat-elements";
import { $get, $post } from "services";
import { timeL2S, timeS2L, timeS2LUnix } from "utils/common";
import { UploadImage } from "./layout.component";
import useLanguage, { LANG } from "hooks/language.hook";

// 聊天室設置 帳號編輯
export const PopupEditChatAccount = ({ isOpen, close, data, refresh }: any) => {
  const [form] = useForm();
  const [avatarType, setAvatarType] = useState(AVATAR_TYPE.預設頭像);
  const [loading, setLoading] = useState(false);
  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  useEffect(() => {
    if (data && isOpen) {
      form.setFieldsValue({
        permission: data.permissionName,
        account: data.backOfficeAccount,
        nickname: data.chatNickname,
        haveAvatar: data.Avatar ? 1 : 0,
      })
      setImageData([
        {
          uid: data.Avatar,
          name: data.Avatar,
          url: data.Avatar,
        },
      ])
      setAvatarType(data.Avatar ? AVATAR_TYPE.自訂頭像 : AVATAR_TYPE.預設頭像);
    }
  }, [data, isOpen])

  const handleUpload = (key: number) => setAvatarType(key);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/chatsetting/account/update',
      send: {
        AccountId: data.AccountId,
        Nickname: formData.nickname,
        Avatar:
          avatarType === AVATAR_TYPE.預設頭像
            ? ''
            : (imageData ? imageData[0].url : data.Avatar)
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        onClose();
        refresh();
      },
      resCode: RESPONSE_CODE_CHAT
    })
  }

  const onClose = () => {
    form.resetFields();
    setImageData([])
    setAvatarType(data.Avatar ? AVATAR_TYPE.自訂頭像 : AVATAR_TYPE.預設頭像);
    close();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="permission" label={i18n.t('backOfficePermissions')} required
              rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="account" label={i18n.t('account')} required
              rules={[{ required: true }]}>
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="nickname" label={i18n.t('chatNickname')} required
              rules={[{ required: true, type: 'string', min: 4, max: 20 }]}>
              <Input placeholder={`4~20${i18n.t('characters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="haveAvatar" label={i18n.t('customerServiceAvatar')} required>
              <Select
                className="full"
                onChange={handleUpload}
                options={[
                  { value: AVATAR_TYPE.預設頭像, label: i18n.t('systemDefault') },
                  { value: AVATAR_TYPE.自訂頭像, label: i18n.t('customization') },
                ]}
              />
            </Form.Item>
          </Col>
          {
            avatarType === AVATAR_TYPE.自訂頭像 &&
            <Col span={24}>
              <Form.Item name="Avatar" valuePropName="fileList" label={i18n.t('image')} 
                rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]}>
                <UploadImage
                  name="Avatar"
                  url={'/upload/images/public/message'}
                  preUrl={data ? data.Avatar  : ''}
                  imageData={imageData}
                  setImageData={setImageData}
                  w={100}
                  h={100}
                  form={form}
                />
              </Form.Item>
              <div className="color-03 size-12">
                {i18n.t('onlyJpgOrPngFiles')}
                <span className="require">{i18n.t('imageMobileVersionWidth100pxHeight100px')}</span>
                {i18n.t('allowedAndSizeNotExceed500kb')}
              </div>
            </Col>
          }
          {
            avatarType === AVATAR_TYPE.預設頭像 &&
            <Col span={24}>
              {Cookies.get('defaultAvatar') ? (
                <Image src={Cookies.get('defaultAvatar')} width={50} height={50} style={{ borderRadius: '50%' }} preview={false} />
              ) : (
                <Avatar size={70} icon={<UserOutlined />} />
              )}
            </Col>
          }
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 棄用
export const PopupDeleteChatReply = ({ isOpen, close, id, refresh }: {
  isOpen: boolean;
  close: () => void;
  id: number | null;
  refresh: () => void;
}) => {

  const onDelete = () => {
    $post({
      url: 'api/chatsetting/cannedresponse/delete',
      send: { Id: id },
      success: () => {
        message.success(i18n.t('deleteSuccess'));
        close();
        refresh();
      },
      resCode: RESPONSE_CODE_CHAT
    })
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button className="mt-1" key="cancel" onClick={close}>{i18n.t('cancel')}</Button>
          <Button className="mt-1" key="confirm" type="primary" onClick={onDelete}>{i18n.t('confirm')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmDelete')}？</div>
      </Space>
    </Modal>
  )
}

export const PopupAddChatReply = ({ isOpen, close, refresh }: any) => {
  const [form] = useForm();
  const onFinish = (value: {
    category: string;
  }) => {
    $post({
      url: 'api/chatsetting/cannedresponse/category/create',
      send: {
        Name: value.category
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
      },
      resCode: RESPONSE_CODE_CHAT
    })
  }

  const onClose = () => {
    form.resetFields();
    close();
    refresh();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="category" label={i18n.t('typeName')} required
              rules={[{ required: true, type: 'string', min: 4, max: 20 }]}>
              <Input placeholder={`4~20${i18n.t('characters')}`} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export const PopupAddChatReplyContent = ({ isOpen, close, Category, refresh }: {
  isOpen: boolean;
  close: () => void;
  Category: ChatQuickReplyCategory[];
  refresh: () => void;
}) => {
  const [form] = useForm();
  const onFinish = (value: {
    categoryName: number;
    content: string;
  }) => {
    $post({
      url: 'api/chatsetting/cannedresponse/create',
      send: {
        CategoryId: value.categoryName,
        Content: value.content
      },
      success: () => {
        message.success(i18n.t('addSuccess'));
        onClose();
      },
      resCode: RESPONSE_CODE_CHAT
    })
  }

  const onClose = () => {
    form.resetFields();
    close();
    refresh();
  }

  return (
    <Modal
      title={i18n.t('add')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="categoryName" label={i18n.t('typeName')} required
              rules={[{ required: true }]}>
              <Select
                className="full"
                placeholder={i18n.t('pleaseSelect')}
                options={
                  Category ?
                    Category.map((item) => (
                      { value: item.Id, label: item.Name }
                    )) : []
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="content" label={i18n.t('content')} required
              rules={[{ required: true, type: 'string', min: 4 }]}>
              <Input.TextArea
                size="middle"
                showCount
                maxLength={200}
                minLength={4}
                placeholder={`4~200${i18n.t('characters')}`}
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

export const PopupEditChatReply = ({ isOpen, close, Category, editData, refresh }: {
  isOpen: boolean;
  close: () => void;
  Category: ChatQuickReplyCategory[];
  editData: any;
  refresh: () => void;
}) => {
  const [form] = useForm();

  useEffect(() => {
    if (editData) {
      form.setFieldsValue({
        category: editData.CategoryId,
        content: editData.Content
      })
    }
  }, [Category, editData]);

  const onFinish = (value: {
    category: number;
    content: string;
  }) => {
    $post({
      url: 'api/chatsetting/cannedresponse/update',
      send: {
        Id: editData.key,
        CategoryId: value.category,
        Content: value.content
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        onClose();
      },
      resCode: RESPONSE_CODE_CHAT
    })
  }

  const onClose = () => {
    close();
    refresh();
  }

  return (
    <Modal
      title={i18n.t('edit')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={false}
      forceRender
    >
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Row gutter={[12, 12]}>
          <Col span={24}>
            <Form.Item name="category" label={i18n.t('typeName')} required
              rules={[{ required: true }]}>
              <Select
                className="full"
                options={
                  Category?.map(item => (
                    { value: item.Id, label: item.Name }
                  )) || []
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="content" label={i18n.t('content')} required
              rules={[{ required: true, type: 'string', min: 4, max: 200 }]}>
              <Input.TextArea
                size="middle"
                showCount
                maxLength={200}
                autoSize={{ minRows: 3, maxRows: 3 }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="center" gutter={[12, 12]}>
              <Col>
                <Button onClick={onClose}>{i18n.t('cancel')}</Button>
              </Col>
              <Col>
                <Button type="primary" htmlType="submit">{i18n.t('confirm')}</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

// 聊天室紀錄 詳情
export const PopupChatHistoryDetail = ({ isOpen, close, role, account, ProcessTime, sessionId, refresh, EndTime }: {
  isOpen: boolean;
  close: () => void;
  role: number | null;
  account: string | null;
  ProcessTime: string | null;
  sessionId: string | null;
  refresh: () => void;
  EndTime: string | null
}) => {
  const [page, setPage] = useState<number[]>([1, 10]);
  const [messageHistory, setMessageHistory] = useState<any>([]);
  const messageListReferance = useRef()
  const { data: Chat, isValidating } = $get({
    url: 'api/chathistory/service/session/message/list',
    params: {
      SessionId: sessionId,
      StartDate: timeL2S(ProcessTime as any),
      EndDate: timeL2S(EndTime as any),
      PageIndex: page[0],
      PageSize: page[1]
    },
    allow: !!sessionId
  })
  useEffect(() => {
    if (Chat) {
      setMessageHistory([
        ...Chat.Data
          .sort((a: ChatMessageInfo, b: ChatMessageInfo) => (
            dayjs(a.SendTime).valueOf() - dayjs(b.SendTime).valueOf()
          )).map((item: ChatInfo) => (
            {
              key: item.Id,
              position: item.SenderRole === ROLE.customerService ? 'right' : 'left',
              avatar: item.SenderRole !== ROLE.customerService ? userDefaultAvatarIcon : '',
              type: item.Type === 0 ? 'text' : 'photo',
              title: item.SenderAccount,
              text: item.Type === 0 ? item.Content : '',
              date: timeS2LUnix(item.SendTime),
              dateString: timeS2LUnix(item.SendTime, 'YYYY-MM-DD HH:mm'),
              data: {
                uri: item.Content,
                width: 200,
                height: 200
              }
            }
          )),
      ])
    }
  }, [Chat]);



  const onClose = () => {
    setPage([1, 10]);
    close();
    refresh();
    setMessageHistory([]);
  }
  // 上滑載入更多歷史訊息
  const [showBackToBottom, setShowBackToBottom] = useState(false);
  const chatWindowRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      setTimeout(() => {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }, 300);
    }
  }, [sessionId]);
  const handleScroll = () => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      const isAtBottom =
        chatWindow.scrollTop + chatWindow.clientHeight === chatWindow.scrollHeight;
      const isAtTop = chatWindow.scrollTop === 0;
      if (isAtBottom) {
        setShowBackToBottom(false);
      } else {
        setShowBackToBottom(true);
      }
      if (isAtTop) {
        setPage([1, page[1] + 10]);
      }
    }
  }
  const scrollToBottom = (delay = 300) => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      setTimeout(() => {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }, delay);
    }
  };

  return (
    <Modal
      title={i18n.t('detail')}
      open={isOpen}
      onCancel={onClose}
      centered
      width={900}
      footer={
        <Row justify="center">
          <Button type="primary" onClick={onClose}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Spin spinning={isValidating}>
        <Descriptions size="small" bordered labelStyle={{ width: 150 }}>
          <Descriptions.Item label={i18n.t('sendMessageRole')}>
            {i18n.t(ROLE[role as number])}
          </Descriptions.Item>
          <Descriptions.Item label={i18n.t('account')}>
            {role === ROLE.visitor ? '-' : account}
          </Descriptions.Item>
        </Descriptions>
        <div ref={chatWindowRef} style={{
          height: 600,
          minHeight: 600,
          maxHeight: 600,
          overflowY: 'auto',
          border: '.5px solid #e3e3e3',
          scrollBehavior: 'smooth',
          position: 'relative'
        }} onScroll={handleScroll}>
          <MessageList
            className='message-list mt-1'
            referance={messageListReferance}
            dataSource={messageHistory}
            lockable={true}
            downButton={true}
            downButtonBadge={10}
            sendMessagePreview={true}
          />
          <div style={{
            display: `${showBackToBottom ? '' : 'none'}`,
            textAlign: 'center',
            position: 'sticky',
            bottom: 0,
          }}>
            <div style={{
              display: 'inline-block',
              padding: '2px 5px',
              border: '.5px solid #e3e3e3',
              borderRadius: 8,
              cursor: 'pointer',
              marginBottom: 5
            }} onClick={() => scrollToBottom(0)}>
              <ArrowDownOutlined />
            </div>
          </div>
        </div>
      </Spin>
    </Modal>
  )
}

export const PopupCloseChat = ({ isOpen, close, sessionId, setSessionId, refresh, setIsContact }: {
  isOpen: boolean;
  close: () => void;
  sessionId: string;
  setSessionId: any;
  refresh: () => void;
  setIsContact: any;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  // 結束對話
  const onEnd = () => {
    $post({
      url: 'api/chatroom/service/session/end',
      send: { SessionId: sessionId },
      success: () => {
        setSessionId('');
        setIsContact(false);
        close();
      },
      resCode: RESPONSE_CODE_CHAT
    }, setIsLoading)
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle" >
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button className="mt-1" onClick={close}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button className="mt-1" type="primary"
              loading={isLoading} onClick={onEnd}>{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('wantToEndConversation')}?</div>
      </Space>
    </Modal>
  )
}

export const PopupOfflineChat = ({ isOpen, close, setChatType, setTabActiveKey }: {
  isOpen: boolean;
  close: () => void;
  setChatType: any;
  setTabActiveKey: any;
}) => {

  const onClose = () => {
    setChatType(CHAT_TYPE.我的對話);
    setTabActiveKey('2')
    close();
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={errorIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('error')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={onClose}
      centered
      width={450}
      footer={
        <Row justify="center">
          <Button type="primary" className="mt-1" onClick={onClose}>{i18n.t('close')}</Button>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('pleaseEndCurrentConversationBeforeGoingOffline')}</div>
      </Space>
    </Modal>
  )
}

// 轉接給其他客服
export const PopupTransferChat = ({ isOpen, close, sessionId, setSessionId, transferInfo, refresh }: {
  isOpen: boolean;
  close: () => void;
  sessionId: string;
  setSessionId: any;
  transferInfo: any[];
  refresh: () => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const onTransfer = () => {
    $post({
      url: 'api/chatroom/service/session/changeanswer',
      send: {
        SessionId: sessionId,
        AccountId: transferInfo[0]
      },
      success: () => {
        message.success(i18n.t('transferSuccess'));
        setSessionId('');
        close();
      },
      resCode: RESPONSE_CODE_CHAT
    }, setIsLoading)
  }

  return (
    <Modal
      title={
        <Row gutter={15} align="middle">
          <Image className="center" src={hintIcon} width={16} height={16} preview={false} />
          <Col className="size-16">{i18n.t('prompt')}</Col>
        </Row>
      }
      open={isOpen}
      onCancel={close}
      centered
      width={450}
      footer={
        <Row justify="center" gutter={[12, 12]}>
          <Col>
            <Button className="mt-1" onClick={close}>{i18n.t('cancel')}</Button>
          </Col>
          <Col>
            <Button className="mt-1" type="primary"
              loading={isLoading} onClick={onTransfer}>{i18n.t('confirm')}</Button>
          </Col>
        </Row>
      }
    >
      <Space className="mt-1" direction="vertical">
        <div>{i18n.t('confirmTransferTo')} {transferInfo ? transferInfo[2] : '-'} - {transferInfo[1] ? transferInfo[1] : Cookies.get('defaultNickname')} ?</div>
      </Space>
    </Modal>
  )
}

export const ViewChatAdminStatus = ({
  form,
  handleConnect,
  tabActiveKey,
  adminState,
  handleServiceState,
  handleServiceNotify,
  serviceNotify,

}: any) => {
  const { data: LanguageList } = $get({ url: 'common/language/list' });
  const { data: UserInfo, isValidating: userInfoLoading, } = $get({ url: 'api/chatsetting/account/info' })
  useEffect(() => {
    if (UserInfo) {
      form.setFieldsValue({
        serviceState: UserInfo.Data.IsOnline
      })
    }
  }, [UserInfo])

  const { setLang } = useLanguage();
  // handle 切換語系
  // ff語系
  const handleChangeLang = async (lang: string) => {
    setLang(lang)
    form.setFieldValue('lang', lang);
  }

  return (
    <Spin spinning={userInfoLoading}>
      <Row align="middle" justify="space-between">
        <Col>
          <Row align="middle" gutter={10}>
            <Col>
              <Avatar size={35} src={UserInfo?.Data.Avatar || Cookies.get('defaultAvatar')} />
            </Col>
            <Col>
              <div className={UserInfo && !!UserInfo?.Data.IsOnline ? 'online' : 'offline'} />
            </Col>
            <Col>
              {UserInfo && UserInfo?.Data.PermissionName} - {UserInfo && (UserInfo?.Data.NickName ? UserInfo?.Data.NickName : UserInfo?.Data.Account)}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row align="middle" gutter={10}>
            <Col>
              <Button type="primary" loading={userInfoLoading}
                onClick={handleConnect} disabled={tabActiveKey !== '1' || adminState === SERVICE_STATUS.離線}>
                {i18n.t('wiring')}
              </Button>
            </Col>
            <Col>
              <Popover trigger="click" title={
                `${UserInfo && UserInfo?.Data.PermissionName} - ${UserInfo && (UserInfo?.Data.NickName ? UserInfo?.Data.NickName : UserInfo?.Data.Account)}`
              } content={
                <Row style={{ width: 200 }} gutter={[12, 12]}>
                  <Col span={24}>
                    <Form.Item name="serviceState"
                      label={<div className={UserInfo && !!UserInfo?.Data.IsOnline ? 'online' : 'offline'} />}>
                      <Select
                        bordered={false}
                        className="w-full"
                        onChange={handleServiceState}
                        options={[
                          { value: SERVICE_STATUS.在線, label: i18n.t('online') },
                          { value: SERVICE_STATUS.離線, label: i18n.t('offline') },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="notify" label={
                      <Image className="center" src={serviceNotify ? bell01Icon : bell02Icon} preview={false} />
                    }>
                      <Select
                        bordered={false}
                        className="w-full"
                        onChange={handleServiceNotify}
                        options={[
                          { value: 1, label: i18n.t('enableNotifications') },
                          { value: 0, label: i18n.t('disableNotifications') },
                        ]}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name="lang" label={<GlobalOutlined />}>
                      <Select
                        bordered={false}
                        className="w-full"
                        onChange={handleChangeLang}
                        options={
                          LanguageList?.Data.map((item: any) => ({ 
                            value: item, 
                            label: LANG[item as keyof typeof LANG] 
                          })) || []
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              }>
                <MoreOutlined style={{ fontSize: 20 }} />
              </Popover>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  )
}

// 聊天室名單
export const ViewChatList = ({
  PendingList,
  tabActiveKey,
  onTabClick,
  chatType,
  handleOpenSession,
  sessionId,
  OpenMyList,
  CloseMyList,
  OpenColleagueList,
  CloseColleagueList,
  firstLoading
}: any) => {
  return (
    <>
      {/* 頁籤 */}
      <Col span={24}>
        <Tabs centered tabBarGutter={100} items={[
          {
            key: '1',
            label: <div>{i18n.t('waiting')}({PendingList?.length || 0})</div>,
          },
          {
            key: '2',
            label: <div >{i18n.t('myConversation')}</div>,
          },
          {
            key: '3',
            label: <div >{i18n.t('colleagueConversation')}</div>,
          },
        ]} defaultActiveKey={tabActiveKey} onTabClick={onTabClick} activeKey={tabActiveKey} />
      </Col>
      <Col span={24} style={{ maxHeight: 700, minHeight: 700, overflowY: "auto" }}>
        {/* 等待中 */}
        <Spin spinning={firstLoading}>
          {
            chatType === CHAT_TYPE.等待中 && tabActiveKey === '1' &&
              PendingList ? PendingList.map((item: ChatPendingList) => (
                <ChatList
                  key={item.Id}
                  {...(item as any)}
                  className="chat-list"
                  onClick={e => handleOpenSession(CHAT_TYPE.等待中, e.id as string, item.CustomerRole, item.CustomerAccount, item.Status)}
                  dataSource={[
                    {
                      id: item.Id,
                      avatar: userDefaultAvatarIcon,
                      title: item.CustomerRole === ROLE.visitor ? `${i18n.t('visitor')}${item.Id}` : item.CustomerAccount,
                      date: timeS2L(item.CreateDate),
                      dateString: timeS2L(item.CreateDate, 'YYYY-MM-DD HH:mm'),
                      className: `${sessionId === item.Id && 'selected-chat'}`
                    }
                  ]}
                />
              )) : []
          }
        </Spin>
        {/* 我的對話 */}
        <Spin spinning={firstLoading}>
          {
            chatType === CHAT_TYPE.我的對話 && tabActiveKey === '2' &&
            <Collapse defaultActiveKey={['1']} items={[
              {
                key: 1,
                label: i18n.t('currentConversation'),
                style: { maxHeight: 340, overflowY: "auto" },
                children: (
                  OpenMyList ? OpenMyList.map((item: ChatPendingList) => (
                    <ChatList
                      key={item.Id}
                      {...(item as any)}
                      onClick={e => handleOpenSession(CHAT_TYPE.我的對話, e.id as string, item.CustomerRole, item.CustomerAccount, item.Status, item.ProcessTime)}
                      className="chat-list"
                      dataSource={[
                        {
                          id: item.Id,
                          avatar: userDefaultAvatarIcon,
                          title: item.CustomerRole === ROLE.visitor ? `${i18n.t('visitor')}${item.Id}` : item.CustomerAccount,
                          subtitle: item.LatestMessageType === 0 ? item.LatestMessage : i18n.t('sentAnImage'),
                          date: timeS2LUnix(item.LatestMessageTime),
                          dateString: timeS2LUnix(item.LatestMessageTime, 'YYYY-MM-DD HH:mm'),
                          className: `${sessionId === item.Id && 'selected-chat'}`,
                          unread: item.UnreadCount
                        }
                      ]}
                    />
                  )) : []
                )
              },
              {
                key: 2,
                label: i18n.t('conversationEnded'),
                style: { maxHeight: 340, overflowY: "auto" },
                children: (
                  CloseMyList ? CloseMyList.map((item: ChatPendingList) => (
                    <ChatList
                      key={item.Id}
                      {...(item as any)}
                      className="chat-list"
                      onClick={e => handleOpenSession(CHAT_TYPE.我的對話, e.id as string, item.CustomerRole, item.CustomerAccount, item.Status, item.ProcessTime)}
                      dataSource={[
                        {
                          id: item.Id,
                          avatar: userDefaultAvatarIcon,
                          title: item.CustomerRole === ROLE.visitor ? `${i18n.t('visitor')}${item.Id}` : item.CustomerAccount,
                          subtitle: item.LatestMessageType === 0 ? item.LatestMessage : i18n.t('sentAnImage'),
                          date: timeS2LUnix(item.LatestMessageTime),
                          dateString: timeS2LUnix(item.LatestMessageTime, 'YYYY-MM-DD HH:mm'),
                          unread: item.UnreadCount
                        }
                      ]}
                    />
                  )) : []
                )
              },
            ]} />
          }
        </Spin>
        {/* 同事對話 */}
        <Spin spinning={firstLoading}>
          {
            chatType === CHAT_TYPE.同事對話 && tabActiveKey === '3' &&
            <Collapse defaultActiveKey={['1']} items={[
              {
                key: 1,
                label: i18n.t('currentConversation'),
                style: { maxHeight: 340, overflowY: "auto" },
                children: (
                  OpenColleagueList ? OpenColleagueList.map((item: ChatPendingList) => (
                    <ChatList
                      key={item.Id}
                      {...(item as any)}
                      className="chat-list"
                      onClick={e => handleOpenSession(CHAT_TYPE.同事對話, e.id as string, item.CustomerRole, item.CustomerAccount, item.Status, item.ProcessTime, item.NoCode)}
                      dataSource={[
                        {
                          id: item.Id,
                          avatar: userDefaultAvatarIcon,
                          title: item.CustomerAccount,
                          subtitle: item.LatestMessageType === 0 ? item.LatestMessage : i18n.t('sentAnImage'),
                          date: timeS2LUnix(item.LatestMessageTime),
                          dateString: timeS2LUnix(item.LatestMessageTime, 'YYYY-MM-DD HH:mm'),
                        }
                      ]}
                    />
                  )) : []
                )
              },
              {
                key: 2,
                label: i18n.t('conversationEnded'),
                style: { maxHeight: 340, overflowY: "auto" },
                children: (
                  CloseColleagueList ? CloseColleagueList.map((item: ChatPendingList) => (
                    <ChatList
                      key={item.Id}
                      {...(item as any)}
                      className="chat-list"
                      onClick={e => handleOpenSession(CHAT_TYPE.同事對話, e.id as string, item.CustomerRole, item.CustomerAccount, item.Status, item.ProcessTime, item.NoCode)}
                      dataSource={[
                        {
                          id: item.Id,
                          avatar: userDefaultAvatarIcon,
                          title: item.CustomerRole === ROLE.visitor ? `${i18n.t('visitor')}${item.Id}` : item.CustomerAccount,
                          subtitle: item.LatestMessageType === 0 ? item.LatestMessage : i18n.t('sentAnImage'),
                          date: timeS2LUnix(item.LatestMessageTime),
                          dateString: timeS2LUnix(item.LatestMessageTime, 'YYYY-MM-DD HH:mm'),
                          unread: item.UnreadCount
                        }
                      ]}
                    />
                  )) : []
                )
              },
            ]} />
          }
        </Spin>
      </Col>
    </>
  )
}

// 對話欄
export const ViewChatInput = ({
  sessionId,
  chatType,
  account,
  onlineAccountLoading,
  refreshOnlineAccount,
  adminState,
  isContact,
  sessionStatus,
  ChatOnlineAccount,
  UserInfo,
  handleTransfer,
  handleScroll,
  messageListReferance,
  serviceMessage,
  showBackToBottom,
  scrollToBottom,
  setInputValue,
  handleKeyDown,
  fileList,
  inputValue,
  handleFileListChange,
  setFileList,
  setSendType,
  isLoading,
  setIsOpenCloseChat,
  onSend,
  firstLoading
}: any) => {
  const chatWindowRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const chatWindow = chatWindowRef.current;
    if (chatWindow) {
      setTimeout(() => {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      }, 300);
    }
  }, [sessionId]);

  return (
    <Spin spinning={firstLoading}>
      <Row>
        {
          sessionId && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) &&
          <>
            <Col span={24}>
              <Card
                bodyStyle={{
                  height: 600,
                  padding: '10px 0px',
                  background: '#F9F9F9'
                }}
                title={
                  <Row align="middle" justify="space-between">
                    <Col>{account}</Col>
                    <Col>
                      <Form.Item name="transfer" className="w-12">
                        <Select
                          loading={onlineAccountLoading}
                          className="w-full"
                          placeholder={i18n.t('transferChat')}
                          onChange={handleTransfer}
                          onDropdownVisibleChange={() => refreshOnlineAccount()}
                          disabled={chatType === CHAT_TYPE.同事對話 || adminState === SERVICE_STATUS.離線 || !isContact || sessionStatus === 2}
                          options={
                            ChatOnlineAccount
                              .filter((item: ChatAccountList) => UserInfo.AccountId !== item.AccountId)
                              .map((item: ChatAccountList) => (
                                {
                                  value: `${item.AccountId},${item.NickName},${item.PermissionName}`,
                                  label: `${item.PermissionName} - ${item.NickName ? item.NickName : item.Account}`,
                                }
                              )) || []
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                }
              >
                <div ref={chatWindowRef} style={{
                  height: 580,
                  minHeight: 580,
                  maxHeight: 580,
                  overflowY: 'auto',
                  scrollBehavior: 'smooth',
                  position: 'relative'
                }} onScroll={handleScroll}>
                  <MessageList
                    className='message-list'
                    referance={messageListReferance}
                    dataSource={serviceMessage}
                    lockable={true}
                    downButton={true}
                    downButtonBadge={10}
                    sendMessagePreview={true}
                  />
                  <div style={{
                    display: `${showBackToBottom ? '' : 'none'}`,
                    textAlign: 'center',
                    position: 'sticky',
                    bottom: 0,
                  }}>
                    <div style={{
                      display: 'inline-block',
                      padding: '2px 5px',
                      border: '.5px solid #e3e3e3',
                      borderRadius: 8,
                      cursor: 'pointer'
                    }} onClick={() => scrollToBottom(0)}>
                      {
                        chatType === CHAT_TYPE.同事對話 || !isContact || sessionStatus === 2
                          ? i18n.t('currentConversationEnded') : i18n.t('newMessage')
                      }
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <Col span={24}
              style={{
                minHeight: 200,
                maxHeight: 200,
                border: '.5px solid #e3e3e3',
                borderRadius: '0px 0px 10px 10px'
              }}>
              {
                chatType === CHAT_TYPE.同事對話 || !isContact || sessionStatus === 2
                  ? ''
                  : <Row>
                    <Col span={24}>
                      <TextArea
                        maxLength={500}
                        autoSize={{ minRows: 5, maxRows: 5 }}
                        placeholder={isContact ? `${i18n.t('inputData')}` : `${i18n.t('conversationEnded')}`}
                        bordered={false}
                        value={inputValue}
                        onChange={e => setInputValue(e.target.value)}
                        onKeyDown={e => handleKeyDown(e)}
                      />
                    </Col>
                    <Col span={24} className="mt-1">
                      <Row align="middle" justify="space-between">
                        <Col className='ml-1'>
                          <Form.Item name="fileList" valuePropName="fileList">
                            <ImgCrop
                              aspect={1}
                              showGrid
                              showReset
                              resetText={`${i18n.t('reset')}`}
                              modalTitle={`${i18n.t('editImage')}`}
                            >
                              <Upload
                                multiple
                                accept=".jpg, .png"
                                fileList={fileList}
                                onChange={handleFileListChange}
                                onRemove={() => {
                                  setFileList([]);
                                  setInputValue('');
                                  setSendType(0);
                                }}
                              >
                                <Image style={{ cursor: 'pointer' }} src={uploadPhotoMessageIcon} preview={false} />
                              </Upload>
                            </ImgCrop>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Row gutter={[12, 12]}>
                            <Col><Button type="primary" danger loading={isLoading} onClick={() => setIsOpenCloseChat(true)}>
                              {i18n.t('endConversation')}
                            </Button></Col>
                            <Col><Button type="primary" className='mr-1' onClick={onSend} loading={isLoading}>{i18n.t('send')}</Button></Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={24}>
                      <Row justify="end">
                        <Col className="color-03 size-12">（{i18n.t('doubleClickEnterToSend')}）</Col>
                      </Row>
                    </Col>
                  </Row>
              }
            </Col>
          </>
        }
      </Row>
    </Spin>
  )
}

// 用戶訊息
export const ViewUserInfo = ({
  sessionId,
  chatType,
}: any) => {
  // 聊天室用戶訊息
  const { data: ChatRoomInfo, isValidating: chatRoomLoading } = $get({
    url: 'api/chatroom/service/session/customer/info',
    params: { SessionId: sessionId },
    allow: !!sessionId
  })

  return (
    <Card title={i18n.t('userInfo')} loading={chatRoomLoading}>
      <Row>
        <Col span={12} className="color-03 size-12">
          <div>
            {i18n.t('system')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? ChatRoomInfo?.Data.System.os : '-'}
          </div>
          <div>
            {i18n.t('role')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? i18n.t(ROLE[ChatRoomInfo?.Data.Role]) : '-'}
          </div>
          <div>
            {i18n.t('nickname')}／{i18n.t('identifier')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? `${ChatRoomInfo?.Data.NickName || '-'}／${ChatRoomInfo?.Data.NoCode || '-'}` : '-'}
          </div>
          <div>
            {i18n.t('registrationTime')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) 
            ? timeS2L(ChatRoomInfo?.Data.RegisterTime)
            : '-'}
          </div>
          <div>
            {i18n.t('lastBetTime')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) 
            ? timeS2L(ChatRoomInfo?.Data.LastBetTime)
            : '-'}
          </div>
        </Col>
        <Col span={12} className="color-03 size-12">
          <div>
            {i18n.t('IP')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? ChatRoomInfo?.Data.IP : '-'}
          </div>
          <div>
            {i18n.t('account')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? ChatRoomInfo?.Data.Account : '-'}
          </div>
          <div>
            {i18n.t('memberLevel')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) ? `${ChatRoomInfo?.Data.Level || '-'}` : '-'}
          </div>
          <div>
            {i18n.t('lastDepositTime')}：{ChatRoomInfo && (chatType === CHAT_TYPE.我的對話 || chatType === CHAT_TYPE.同事對話) 
            ? timeS2L(ChatRoomInfo?.Data.LastDepositTime)
            : '-'}
          </div>
        </Col>
      </Row>
    </Card>
  )
}

export const ViewQuickReply = ({
  chatType,
  isContact,
  sessionStatus,
  setInputValue,
}: any) => {
  const { DirectoryTree } = Tree;
  // 快捷回覆
  const { data: QuickReply, isValidating: quickReplyLoading } = $get({ url: 'api/chatsetting/cannedresponse/all/list' });

  // handle 快捷回覆
  const onDoubleClick: DirectoryTreeProps['onDoubleClick'] = (keys, info) => {
    if (info.children) return;
    if (chatType === CHAT_TYPE.同事對話 || !isContact || sessionStatus === 2) return;
    setInputValue(`${info.title}`);
  }

  return (
    <Card title={
      <Row align="middle" justify="space-between">
        <Col>{i18n.t('quickReply')}</Col>
      </Row>
    } loading={quickReplyLoading}>
      <Row style={{ maxHeight: 600, overflowY: "auto" }}>
        <Col span={24}>
          <DirectoryTree
            onDoubleClick={onDoubleClick}
            treeData={
              [...new Set(QuickReply?.Data.map((item: QuickReplyList) => item.CategoryName))]
                .map((CategoryName, i) => (
                  {
                    key: i,
                    title: `${CategoryName}`,
                    children: [
                      ...QuickReply?.Data.filter((item: QuickReplyList) =>
                        item.CategoryName === CategoryName)
                        .map((item: QuickReplyList) => (
                          {
                            key: `${i}-${item.Id}`,
                            title: item.Content,
                            isLeaf: true,
                            style: {
                              whiteSpace: 'nowrap', overflow: 'hidden',
                              textOverflow: 'ellipsis', maxWidth: 400,
                              display: 'inline-block'
                            },
                          }
                        ))
                    ]
                  }
                )) || []
            }
          />
        </Col>
      </Row>
    </Card>
  )
}