import { InfoCircleFilled } from '@ant-design/icons';
import { Button, Col, Descriptions, Form, Input, InputNumber, Row, Select, Space, Tooltip, message } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { useForm } from 'antd/lib/form/Form';
import { UploadFile } from 'antd/lib/upload/interface';
import { DateSelector, LayoutNav, UploadImage } from 'components/layout.component';
import { MemberLevelSelect } from 'components/member.component';
import { CKEditorComp } from 'components/promotion.component';
import { RESPONSE_CODE_BONUS } from 'constants/response';
import dayjs from 'dayjs';
import { DATE_RANGE_LIMIT } from 'enum/date';
import { PROMOTION_STATE } from 'enum/promotion';
import { WHETHER } from 'enum/state';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { $get, $post } from 'services';
import { enumToOptions, timeL2S, timeS2L, verify } from "utils/common";

// 優惠活動管理 編輯每日簽到

const PageMain: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState([
    dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    null
  ]);
  const [editorContent, setEditorContent] = useState<string>();
  const [isPopupValue, setIsPopupValue] = useState(false);
  const { data: checkInBonus } = $get({ url: 'api/bonus/checkin/info', params: { BonusId: id } })

  const { data: MemberLevel, isValidating } = $get({
    url: 'api/memberlevel/bonus/singin/list',
    params: { BonusId: id }
  });

  useEffect(() => {
    if (checkInBonus) {
      const DaileyRule = JSON.parse(checkInBonus.Data.BonusRuleSetting);
      const LevelsId = JSON.parse(checkInBonus.Data.LevelsId);
      form.setFieldsValue({
        promotionName: checkInBonus.Data.Name,
        LevelId: LevelsId,
        day01: DaileyRule.Dailey.Amount[0].Amount,
        day02: DaileyRule.Dailey.Amount[1].Amount,
        day03: DaileyRule.Dailey.Amount[2].Amount,
        day04: DaileyRule.Dailey.Amount[3].Amount,
        day05: DaileyRule.Dailey.Amount[4].Amount,
        day06: DaileyRule.Dailey.Amount[5].Amount,
        day07: DaileyRule.Dailey.Amount[6].Amount,
        cycleAmount01: DaileyRule.Dailey.CycleAmount,
        requiresContinuousSignIn: DaileyRule.IsContinuous,

        day10: DaileyRule.Cumulative.Amount[0].Amount,
        day20: DaileyRule.Cumulative.Amount[1].Amount,
        day30: DaileyRule.Cumulative.Amount[2].Amount,
        day40: DaileyRule.Cumulative.Amount[3].Amount,
        day50: DaileyRule.Cumulative.Amount[4].Amount,
        day60: DaileyRule.Cumulative.Amount[5].Amount,
        cycleAmount02: DaileyRule.Cumulative.CycleAmount,
        accumulatedCycleAmount: DaileyRule.Cumulative.CycleAmount,

        rolloverRate01: checkInBonus.Data.ValidBetTimes,
        rolloverRate02: DaileyRule.Cumulative.ValidBetTimes,
        distributionMethod: checkInBonus.Data.SettleMethod,
        popupDisplay: checkInBonus.Data.IsPopUp,
        redirectToPromotionPage: checkInBonus.Data.IsRedirect,
        status: checkInBonus.Data.Status,
        sort: checkInBonus.Data.Position,
        internalContent: checkInBonus.Data.Content
      })
      setImageData([
        {
          uid: checkInBonus.Data.Photo,
          name: checkInBonus.Data.Photo,
          url: checkInBonus.Data.Photo,
        },
      ])
      setEditorContent(checkInBonus.Data.Content);
      setIsPopupValue(checkInBonus.Data.IsPopUp !== 1)
      setDate([timeS2L(checkInBonus.Data.StartDate), timeS2L(checkInBonus.Data.EndDate), null])
    }
  }, [checkInBonus]);

  const onFinish = (formData: any) => {
    $post({
      url: 'api/bonus/checkin/update',
      send: {
        Id: id,
        Name: formData.promotionName,
        LevelId: formData.LevelId,
        DaileyRule: {
          CycleAmount: Number(formData.cycleAmount01),
          Amount: [
            { Day: 1, Amount: Number(formData.day01) },
            { Day: 2, Amount: Number(formData.day02) },
            { Day: 3, Amount: Number(formData.day03) },
            { Day: 4, Amount: Number(formData.day04) },
            { Day: 5, Amount: Number(formData.day05) },
            { Day: 6, Amount: Number(formData.day06) },
            { Day: 7, Amount: Number(formData.day07) }
          ]
        },
        IsContinuous: Number(formData.requiresContinuousSignIn),
        DaileyValidBetTimes: Number(formData.rolloverRate01),
        CumulativeRule: {
          CycleAmount: Number(formData.cycleAmount02),
          Amount: [
            { Day: 10, Amount: Number(formData.day10) },
            { Day: 20, Amount: Number(formData.day20) },
            { Day: 30, Amount: Number(formData.day30) },
            { Day: 40, Amount: Number(formData.day40) },
            { Day: 50, Amount: Number(formData.day50) },
            { Day: 60, Amount: Number(formData.day60) }
          ],
        },
        CumulativeValidBetTimes: Number(formData.rolloverRate02),
        IsPopUp: formData.popupDisplay,
        IsRedirect: formData.popupDisplay === 1 ? formData.redirectToPromotionPage : 0,
        Status: formData.status,
        Position: formData.sort,
        StartDate: timeL2S(date[0] as string),
        EndDate: timeL2S(date[1] as string),
        Photo: imageData[0] ? imageData[0].url : '',
        Content: editorContent,
      },
      success: () => {
        message.success(i18n.t('editSuccess'));
        navigate('/promotion');
        setLoading(false);
      },
      resCode: RESPONSE_CODE_BONUS
    }, setLoading)
  }

  const [imageData, setImageData] = useState<UploadFile<any>[]>([]);

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2">
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Col>
                  <Form.Item name="promotionName" label={i18n.t('promotionName')} rules={[{ required: true },
                  { type: 'string', max: 100, message: `${i18n.t('promotionActivityNameLimitedTo100Characters')}` }]}>
                    <Input className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Space size="middle">
                    <Button onClick={() => navigate('/promotion')}>{i18n.t('cancel')}</Button>
                    <Button type="primary" htmlType="submit" loading={loading}>{i18n.t('confirm')}</Button>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row>
                <Col>
                  <Form.Item name="LevelId" style={{ minWidth: '12rem' }} label={
                    <>
                      <span>{i18n.t('memberLevel')}</span>
                      <span className="color-03">{`(${i18n.t('selectableMultiple')})`}</span>
                    </>
                  }>
                    <MemberLevelSelect mode="multiple" name="LevelId" form={form}
                      bonusData={MemberLevel?.Data} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 優惠金額 */}
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item name="promotionAmount" label={i18n.t('promotionAmount')} required>
                    <Descriptions size="small" layout="vertical" column={8} bordered>
                      <Descriptions.Item label={i18n.t('day1')}>
                        <Form.Item name="day01" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('day2')}>
                        <Form.Item name="day02" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('day3')}>
                        <Form.Item name="day03" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('day4')}>
                        <Form.Item name="day04" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('day5')}>
                        <Form.Item name="day05" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('day6')}>
                        <Form.Item name="day06" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('day7')}>
                        <Form.Item name="day07" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label={
                        <div>
                          {i18n.t('cycleAmount')}
                          <Tooltip placement="top" title={i18n.t('startOnDay8')}>
                            <InfoCircleFilled style={{ marginLeft: 2 }} />
                          </Tooltip>
                        </div>
                      }>
                        <Form.Item name="cycleAmount01" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item className="w-12" name="requiresContinuousSignIn" label={i18n.t('requiresContinuousSignIn')}
                    rules={[{ required: true }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="rolloverRate01" label={i18n.t('rolloverRate')} rules={verify({ point: 0 })}>
                    <InputNumber className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            {/* 連續累積金額 */}
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item name="accumulatedAmount" label={i18n.t('accumulatedAmount')} required>
                    <Descriptions size="small" layout="vertical" column={7} bordered>
                      <Descriptions.Item label={i18n.t('accumulatedDay10')}>
                        <Form.Item name="day10" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('accumulatedDay20')}>
                        <Form.Item name="day20" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('accumulatedDay30')}>
                        <Form.Item name="day30" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('accumulatedDay40')}>
                        <Form.Item name="day40" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('accumulatedDay50')}>
                        <Form.Item name="day50" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label={i18n.t('accumulatedDay60')}>
                        <Form.Item name="day60" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                      <Descriptions.Item label={
                        <div>
                          {i18n.t('accumulatedCycleAmount')}
                          <Tooltip placement="top" title={i18n.t('startOnDay70')}>
                            <InfoCircleFilled style={{ marginLeft: 2 }} />
                          </Tooltip>
                        </div>
                      }>
                        <Form.Item name="cycleAmount02" rules={verify({ point: 4, max: 999999999 })}>
                          <InputNumber className="w-full" placeholder={`${i18n.t('inputData')}`} />
                        </Form.Item>
                      </Descriptions.Item>
                    </Descriptions>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item name="rolloverRate02" label={i18n.t('consecutiveAccumulatedAmountWageringMultiplier')}
                    rules={verify({ point: 0 })}>
                    <InputNumber className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="distributionMethod" label={i18n.t('distributionMethod')} rules={[{ required: true }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      disabled
                      defaultValue={0}
                      options={[
                        { value: 0, label: `${i18n.t('systemDistribution')}` },
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item className="w-12" name="popupDisplay" label={i18n.t('popupDisplay')}
                    rules={[{ required: true }]}>
                    <Select
                      onChange={e => {
                        setIsPopupValue(e !== 1);
                        form.setFieldValue('redirectToPromotionPage', 0);
                      }}
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item className="w-12" name="redirectToPromotionPage" label={i18n.t('redirectToPromotionPage')}
                    rules={[{ required: !isPopupValue }]}>
                    <Select
                      disabled={isPopupValue}
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(WHETHER)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col>
                  <Form.Item className="w-12" name="status" label={i18n.t('status')} rules={[{ required: true }]}>
                    <Select
                      placeholder={i18n.t('pleaseSelect')}
                      options={enumToOptions(PROMOTION_STATE)}
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="sort" label={i18n.t('sort')} rules={verify({ point: 0 })}>
                    <InputNumber className="w-12" placeholder={`${i18n.t('inputData')}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Form.Item name="promotionTime" label={`${i18n.t('promotionStartTime')} - ${i18n.t('promotionEndTime')}`}
                rules={[{ required: true, message: `${i18n.t('required')}` }]}>
                <DateSelector form={form} name="promotionTime" width={396} date={date} setDate={setDate} displayCom={['Picker']} 
                  rangeLimit={DATE_RANGE_LIMIT.future} />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="Photo" valuePropName="fileList" label={i18n.t('image')} 
                rules={[{ required: !imageData[0], message: `${i18n.t('uploadImage')}` }]}>
                <UploadImage
                  name="Photo"
                  url={'/upload/images/public/bonus'}
                  preUrl={checkInBonus ? checkInBonus.Data.Photo : ''}
                  imageData={imageData}
                  setImageData={setImageData}
                  form={form}
                  w={1920}
                  h={560}
                />
              </Form.Item>
              <div className="color-03 size-12">
                {i18n.t('onlyJpgOrPngFiles')}
                <span className="require">{i18n.t('imageMobileVersionWidth1920pxHeight560px')}</span>
                {i18n.t('allowedAndSizeNotExceed500kb')}
              </div>
            </Col>
            <Col span={24}>
              <Row>
                <Col span={24}>
                  <Form.Item name="internalContent" label={i18n.t('internalContent')}
                    rules={[{ required: true }]}>
                    <CKEditorComp data={editorContent} setData={setEditorContent} form={form} fieldName={'internalContent'} image />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Content>
    </div >
  );
};

export default PageMain;