import { Button, Col, Form, Input, Row, Select, Table } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Content } from 'antd/es/layout/layout';
import { PopupEditMerchant } from 'components/finance.component';
import { LayoutNav, LayoutPagination, MemberLevelTableDisplay } from 'components/layout.component';
import { MemberLevelSelect } from 'components/member.component';
import { TRANSFER_SETTING_STATUS, TRANSFER_TYPE } from 'enum/state';
import useAccount from 'hooks/account.hook';
import i18n from 'i18n';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { $get } from 'services';
import { setSearchHistory } from 'slice/searchSlice';
import * as common from 'utils/common';

// 三方出入款管理

const PageMain: React.FC = () => {
  const { init, permissions: $p } = useAccount();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = useForm();
  const [Id, setId] = useState<number>(0);
  const [type, setType] = useState<any>(TRANSFER_TYPE.deposit);
  const [editPop, setEditPop] = useState(false);
  const [params, setParams] = useState<any>({});
  const [page, setPage] = useState<number[]>([1, 10]);
  const [listDepositData, setListDepositData] = useState<any>([]);
  const [listWithdraw, setListWithdraw] = useState<any>([]);
  const { data: DepositMerchant } = $get({ url: 'api/depositmerchant/name/list' });
  const { data: DepositList, isValidating: isDepositLoading, mutate: refreshDeposit } = $get({
    url: 'api/depositmerchant/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    },
    allow: type === TRANSFER_TYPE.deposit
  })
  const { data: WithdrawMerchant } = $get({ url: 'api/withdrawmerchant/name/list' });
  const { data: WithdrawList, isValidating: isWithdrawLoading, mutate: refreshWithdraw } = $get({
    url: 'api/withdrawmerchant/list',
    params: {
      ...params,
      PageIndex: page[0],
      PageSize: page[1]
    },
    allow: type === TRANSFER_TYPE.withdrawal
  })
  const [merchantData, setMerchantData] = useState<any>(DepositMerchant?.Data);

  useEffect(() => {
    if ($p('21201')) {
      setType(TRANSFER_TYPE.deposit);
      setMerchantData(DepositMerchant?.Data)
    } else if ($p('21001')) {
      setType(TRANSFER_TYPE.withdrawal);
      setMerchantData(WithdrawMerchant?.Data)
    }
  }, [init, DepositMerchant, WithdrawMerchant]);

  useEffect(() => {
    if (DepositList) {
      setListDepositData(DepositList?.Data)
    }
    if (WithdrawList) {
      setListWithdraw(WithdrawList?.Data);
    }
  }, [DepositList, WithdrawList]);


  const handleMerchantType = (type: number) => {
    setType(type);
    onClear();
    mutate();

    switch (type) {
      case TRANSFER_TYPE.deposit:
        setListDepositData(DepositList?.Data)
        setMerchantData(DepositMerchant?.Data)
        break;
      case TRANSFER_TYPE.withdrawal:
        setListWithdraw(WithdrawList?.Data)
        setMerchantData(WithdrawMerchant?.Data)
        break;
      default:
        break;
    }
  }

  const search = (formData: any) => {
    setPage([1, page[1]]);
    setParams({
      ...params,
      Name: formData.transferType01,
      CustomName: formData.displayName,
      LevelId: formData.LevelId
    })
    mutate();
  }

  const onClear = () => {
    form.resetFields();
    setPage([1, 10]);
  }

  const mutate = () => {
    refreshDeposit();
    refreshWithdraw();
  }

  const colorChange = (type: number) => {
    switch (type) {
      case TRANSFER_SETTING_STATUS.enabled:
        return 'color-pass'
      case TRANSFER_SETTING_STATUS.disable:
        return 'color-reject'
      case TRANSFER_SETTING_STATUS.delete:
        return 'color-padding'
    }
  }

  return (
    <div id="container">
      <LayoutNav />
      <Content className="ph-2">
        <Form form={form} onFinish={search}>
          <Row gutter={[12, 12]}>
            <Col span={24}>
              <Row align="middle" gutter={[12, 12]}>
                <Col>
                  {
                    ($p('21201') && !$p('21001')) &&
                    <Select
                      className="w-12"
                      defaultValue={TRANSFER_TYPE.deposit}
                      options={[
                        { value: TRANSFER_TYPE.deposit, label: i18n.t('deposit') },
                      ]}
                      onChange={handleMerchantType}
                    />
                  }
                  {
                    ($p('21001') && !$p('21201')) &&
                    <Select
                      className="w-12"
                      defaultValue={TRANSFER_TYPE.withdrawal}
                      options={[
                        { value: TRANSFER_TYPE.withdrawal, label: i18n.t('withdraw') },
                      ]}
                      onChange={handleMerchantType}
                    />
                  }
                  {
                    ($p('21001') && $p('21201')) &&
                    <Select
                      className="w-12"
                      defaultValue={TRANSFER_TYPE.deposit}
                      options={[
                        { value: TRANSFER_TYPE.deposit, label: i18n.t('deposit') },
                        { value: TRANSFER_TYPE.withdrawal, label: i18n.t('withdraw') },
                      ]}
                      onChange={handleMerchantType}
                    />
                  }
                </Col>
                <Col>
                  <Form.Item name="transferType01" className="w-12">
                    <Select
                      placeholder={i18n.t('selectThirdPartyName')}
                      options={
                        merchantData?.map((item: any) => (
                          { value: item.ThirdMerchantName, label: item.ThirdMerchantName }
                        ))
                      }
                    />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item name="displayName">
                    <Input placeholder={`${i18n.t('displayName')}`} />
                  </Form.Item>
                </Col>
                <Col className="w-12">
                  <Form.Item name="LevelId">
                    <MemberLevelSelect name="LevelId" form={form} />
                  </Form.Item>
                </Col>
                <Col>
                  <Button type="primary" htmlType="submit">{i18n.t('search')}</Button>
                </Col>
                <Col>
                  <Button onClick={onClear}>{i18n.t('clear')}</Button>
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Table
                size="middle"
                loading={isWithdrawLoading || isDepositLoading}
                scroll={{ x: 1500 }}
                dataSource={
                  type === TRANSFER_TYPE.deposit && $p('21201') ?
                    listDepositData?.map((item: DepositMerchantList) => ({ key: item.Id, ...item }))
                    : (type === TRANSFER_TYPE.withdrawal && $p('21001') ?
                      listWithdraw?.map((item: DepositMerchantList) => ({ key: item.Id, ...item }))
                      : []
                    )
                }
                columns={[
                  {
                    dataIndex: 'Position',
                    title: i18n.t('sort'),
                    fixed: 'left',
                    align: 'center',
                    width: 50
                  },
                  {
                    dataIndex: 'Name',
                    title: i18n.t('thirdPartName'),
                    fixed: 'left',
                    width: 180
                  },
                  {
                    dataIndex: 'CustomName',
                    title: i18n.t('displayName'),
                    width: 180
                  },
                  {
                    dataIndex: 'GatewayCode',
                    title: i18n.t('channelName'),
                    width: 180,
                    render:(val) => i18n.t(val)
                  },
                  {
                    dataIndex: 'LevelName',
                    title: i18n.t('applicableMemberLevel'),
                    width: 150,
                    render: (val) => <MemberLevelTableDisplay levelName={val} noneIsAll={false} />
                  },
                  {
                    dataIndex: 'Rate',
                    title: i18n.t('paymentFeePercentageTransactionFee'),
                    align: 'right',
                    width: 150,
                    render: (val, { HandlingFee }) => <>{`${common.convertedToPercentage01(val)} %`} / {common.toFormatNumber(HandlingFee)}</>
                  },
                  {
                    dataIndex: 'TotalCount',
                    title: i18n.t('totalTransactionsToday'),
                    width: 150,
                    align: 'right',
                    render: (val, record) => (
                      <>
                        {
                          $p('21301') && type === TRANSFER_TYPE.deposit
                            ? <Button type="link" onClick={() => {
                              dispatch(setSearchHistory({ text: { bankName: record.Name, bankDisplayName: record.CustomName } }))
                              navigate(`/finance/payment-depositTotal/${record.Id}`);
                            }}>
                              {common.toFormatNumber(val, 0)}
                            </Button>
                            : (
                              $p('21101') && type === TRANSFER_TYPE.withdrawal
                                ? <Button type="link" onClick={() => {
                                  dispatch(setSearchHistory({ text: { bankName: record.Name, bankDisplayName: record.CustomName } }))
                                  navigate(`/finance/payment-withdrawTotal/${record.Id}`);
                                }}>
                                  {common.toFormatNumber(val, 0)}
                                </Button>
                                : common.toFormatNumber(val, 0)
                            )
                        }
                      </>
                    )
                  },
                  {
                    dataIndex: 'TotalAmount',
                    title: type === TRANSFER_TYPE.deposit ? i18n.t('totalDepositsToday') : i18n.t('totalWithdrawalToday'),
                    width: 150,
                    align: 'right',
                    render: (val, record) => (
                      <>
                        {
                          $p('21301') && type === TRANSFER_TYPE.deposit
                            ? <Button type="link" onClick={() => {
                              dispatch(setSearchHistory({ text: { bankName: record.Name, bankDisplayName: record.CustomName } }))
                              navigate(`/finance/payment-depositTotal/${record.Id}`);
                            }}>
                              {common.toFormatNumber(val, 0)}
                            </Button>
                            : (
                              $p('21101') && type === TRANSFER_TYPE.withdrawal
                                ? <Button type="link" onClick={() => {
                                  dispatch(setSearchHistory({ text: { bankName: record.Name, bankDisplayName: record.CustomName } }))
                                  navigate(`/finance/payment-withdrawTotal/${record.Id}`);
                                }}>
                                  {common.toFormatNumber(val, 0)}
                                </Button>
                                : common.toFormatNumber(val, 0)
                            )
                        }
                      </>
                    )
                  },
                  {
                    dataIndex: 'Status',
                    title: i18n.t('status'),
                    width: 100,
                    render: (val) => <div className={colorChange(val)}>{i18n.t(TRANSFER_SETTING_STATUS[val])}</div>
                  },
                  {
                    title: i18n.t('operation'),
                    fixed: 'right',
                    width: 100,
                    render: (_, record) => (
                      <>
                        {
                          ($p('21003') && $p('21203')) &&
                          <Button type="link" onClick={() => {
                            setEditPop(true);
                            setId(record.key);
                          }}>{i18n.t('edit')}</Button>
                        }
                      </>
                    )
                  },
                ]}
                pagination={false}
              />
              <LayoutPagination total={
                type === TRANSFER_TYPE.deposit
                  ? (DepositList?.TotalRecord || 0)
                  : (WithdrawList?.TotalRecord || 0)
              } setPage={setPage} page={page} />
            </Col>
          </Row>
        </Form>
      </Content>
      <PopupEditMerchant isOpen={editPop} close={() => setEditPop(false)} id={Id} mutate={mutate} type={type} />
    </div >
  );
};

export default PageMain;